<template>
  <div>
    <b-card class="mt-4 mb-4 mx-2 mx-md-4" border-variant="success">
      <b-card-header header-bg-variant="light" align="left">
        <b-row
          ><b-col cols="7" md="8">
            <div class="author">
              <span @click="showImageModal = true">
                <b-avatar
                  size="3rem"
                  class="avatar-size"
                  :src="
                    article.authorImage
                      ? 'data:image/png;base64,' + article.authorImage
                      : ''
                  "
                ></b-avatar
              ></span>
              <div class="ml-3 mt-2 author-div">
                <span class="author-name"
                  ><router-link
                    class="author-name__link"
                    :to="`/profile/${article.author.id}`"
                    >{{ article.author.name }},
                  </router-link></span
                >
                <span class="author-time">
                  {{ article.time | timeFilter }}</span
                >

                <span class="read-count" title="Views" v-if="article.views">
                  <b-icon-eye-fill font-scale="1" class="mr-2"></b-icon-eye-fill
                  >{{ article.views | numberFilter }}</span
                >
              </div>
            </div> </b-col
          ><b-col cols="5" md="4">
            <div class="header-icon-group">
              <b-icon-arrows-angle-contract
                v-if="postViewerMode"
                font-scale="1"
                class="mr-2 mb-1"
                @click="goToPostViewer(article._id)"
              ></b-icon-arrows-angle-contract>
              <b-icon-arrows-angle-expand
                v-else
                font-scale="1"
                class="mr-2 mb-1"
                @click="goToPostViewer(article._id)"
              ></b-icon-arrows-angle-expand>
              <b-dropdown
                size="md"
                right
                variant="warn"
                toggle-class="text-decoration-none"
                no-caret
                id="three-dots"
              >
                <template #button-content>
                  <b-icon-three-dots-vertical
                    class="mr-2 mb-1"
                  ></b-icon-three-dots-vertical>
                </template>
                <b-dropdown-item
                  v-for="item in actionItem"
                  :key="item"
                  @click="takeAction(item)"
                  >{{ item }}</b-dropdown-item
                >
              </b-dropdown>
              <b-icon-bookmark-fill
                v-if="bookmarked"
                variant="warning"
                font-scale="2"
                :title="'Bookmark'"
                class="bookmark-icon mr-2 mt-1"
                :animation="isBookmarkLoading ? 'fade' : ''"
                @click="bookmark(article._id)"
              ></b-icon-bookmark-fill>
              <b-icon-bookmark
                v-else
                font-scale="2"
                :title="'Bookmark'"
                :animation="isBookmarkLoading ? 'fade' : ''"
                class="bookmark-icon mr-2 mt-1"
                @click="bookmark(article._id)"
              ></b-icon-bookmark></div></b-col
        ></b-row>
      </b-card-header>

      <b-card-body class="article-content">
        <p class="article-header my-2">{{ article.title | headerFilter }}</p>
        <p class="article-body">
          {{ showReadMore ? trimedContent : article.content }}
          <span v-if="showReadMore">
            ...
            <router-link :to="`/post/${article._id}`"
              >read more</router-link
            ></span
          >
        </p>
      </b-card-body>

      <b-card-footer class="like-bar">
        <div>
          <b-icon-heart-fill
            v-if="likedAlready"
            class="heart-style"
            font-scale="1"
            @click="liked"
            variant="danger"
          ></b-icon-heart-fill>
          <b-icon-heart
            v-else
            class="heart-style"
            font-scale="1"
            @click="liked"
            variant="success"
          ></b-icon-heart>

          <span
            v-if="likesCount > 0"
            class="mx-2 people-liked"
            @click="fetchPeopleLikedList()"
            >{{ likesCount | numberFilter }}</span
          >
          <span v-else class="ml-3"></span>

          <button v-if="isFetchingComment" type="text" disabled class="ml-2">
            <b-spinner small variant="info"></b-spinner>
          </button>
          <b-icon-chat-left-dots-fill
            v-else
            class="heart-style ml-2"
            font-scale="1"
            variant="info"
            @click="getComments()"
          ></b-icon-chat-left-dots-fill>

          <span class="mx-2" v-if="article.comments.length > 0">
            {{ article.comments.length | numberFilter }}
          </span>

          <button
            @click="socialShareModal = true"
            type="text"
            style="float: right"
          >
            <span style="font-size: 0.8rem">Share</span>
            <b-icon-share-fill
              variant="info"
              font-scale="1"
              class="ml-1"
            ></b-icon-share-fill>
          </button>
        </div>

        <hr class="mb-2" />

        <div v-if="showComments">
          <div v-for="(item, index) in article.comments" :key="item.id">
            <div class="comment my-1">
              <b-avatar
                size="3rem"
                class="avatar-size"
                :src="item.image ? 'data:image/png;base64,' + item.image : ''"
              ></b-avatar>

              <div class="ml-3">
                <span class="comment__name">{{ item.user }},</span>
                <span class="comment__time">
                  {{ item.time | timeFilterComment }}</span
                ><br />
                <span class="comment__body">{{ item.comment }}</span>
              </div>
              <template v-if="userDetails.id === item.userId">
                <b-dropdown
                  size="sm"
                  right
                  class="mb-3"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
                  </template>
                  <b-dropdown-item @click="editComment(item, index)">
                    <b-icon-pencil-square
                      variant="primary"
                    ></b-icon-pencil-square
                    >&nbsp; Edit</b-dropdown-item
                  >
                  <b-dropdown-item @click="deleteComment(item)">
                    <b-icon-trash-fill variant="danger"></b-icon-trash-fill
                    >&nbsp; Delete</b-dropdown-item
                  >
                </b-dropdown>
              </template>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <b-row>
            <b-col cols="9" md="11">
              <b-form-input
                ref="commentBox"
                v-model="myComment"
                size="sm"
                placeholder="Comment"
              ></b-form-input
            ></b-col>

            <b-col cols="3" md="1">
              <b-button
                variant="primary"
                size="sm"
                style="float: left"
                @click="postComment"
                :disabled="myComment.length === 0"
                >Send</b-button
              ></b-col
            >
          </b-row>
        </div>
      </b-card-footer>
    </b-card>
    <template v-if="postViewerMode">
      <br />
      <b-button variant="primary" @click="$router.push('/home')">
        <b-icon-house-fill></b-icon-house-fill>
        Home</b-button
      >
      <br />
      <br />
    </template>

    <b-modal
      v-model="showDeleteModal"
      title="Confirm Delete"
      header-bg-variant="info"
      header-text-variant="default"
      centered
      no-close-on-backdrop
    >
      <b-container fluid
        ><p>
          Your {{ article.category }}, <strong>{{ article.title }}</strong> will
          be deleted permanently.
        </p>
        <br />
        <p>Do you wish to Continue?</p>
      </b-container>

      <template #modal-footer>
        <b-button
          size="md"
          variant="outline-primary"
          @click="showDeleteModal = false"
        >
          Cancel
        </b-button>
        <b-button v-if="isDeleting" variant="danger" disabled>
          Deleting...
        </b-button>
        <b-button
          v-else
          size="md"
          variant="outline-danger"
          @click="deleteArticle()"
        >
          Delete
        </b-button>
      </template>
    </b-modal>
    <b-modal
      v-model="showLoginModal"
      title="You are not Logged in!"
      header-bg-variant="info"
      header-text-variant="default"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <SignIn @cancelled="showLoginModal = false" />
    </b-modal>
    <LikedPeopleModal
      :people-list="peopleList"
      v-if="showPeopleModal"
      :isLoading="likeModalLoader"
      @closedModal="showPeopleModal = false"
    ></LikedPeopleModal>
    <ReportModal
      v-if="showReportModal"
      :reported-article="article"
      @closedModal="showReportModal = false"
    ></ReportModal>
    <SocialShareModal
      v-if="socialShareModal"
      :link="article._id"
      @closedModal="socialShareModal = false"
    ></SocialShareModal>
    <ImageModal
      v-if="showImageModal"
      :imgSrc="article.authorImage"
      @closedModal="showImageModal = false"
    ></ImageModal>
  </div>
</template>

<script>
import moment from "moment/src/moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EventBus } from "../router/EventBus";
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BButton,
  BModal,
  BIconEyeFill,
  BIconArrowsAngleContract,
  BIconArrowsAngleExpand,
  BIconThreeDotsVertical,
  BIconChatLeftDotsFill,
  BIconTrashFill,
  BIconPencilSquare,
  BIconHeart,
  BIconHeartFill,
  BIconShareFill,
  BIconBookmark,
  BIconBookmarkFill,
  BSpinner,
  BIconHouseFill,
} from "bootstrap-vue";

export default {
  components: {
    LikedPeopleModal: () =>
      import(/* webpackPrefetch: true */ "./modals/LikedPeopleModal.vue"),
    ReportModal: () =>
      import(/* webpackPrefetch: true */ "./modals/ReportModal.vue"),
    SocialShareModal: () =>
      import(/* webpackPrefetch: true */ "./modals/SocialShareModal.vue"),
    ImageModal: () =>
      import(/* webpackPrefetch: true */ "./modals/ImageModal.vue"),
    SignIn: () => import(/* webpackPrefetch: true */ "./modals/SignIn.vue"),
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BModal,
    BIconEyeFill,
    BIconArrowsAngleContract,
    BIconArrowsAngleExpand,
    BIconThreeDotsVertical,
    BIconChatLeftDotsFill,
    BIconTrashFill,
    BIconPencilSquare,
    BIconHeart,
    BIconHeartFill,
    BIconShareFill,
    BIconBookmark,
    BIconBookmarkFill,
    BIconHouseFill,
    BSpinner,
  },

  props: {
    article: {
      required: true,
      type: Object,
    },
    readMore: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      myComment: "",
      showComments: false,
      bookmarked: false,
      likedAlready: false,
      likesCount: 0,
      dismissSecs: 3000,
      wordsCount: 0,
      wordnLineCount: 0,
      trimedContent: "",
      wordThreshold: 250,
      actionItem: [],
      postViewerMode: false,
      showDeleteModal: false,
      showPeopleModal: false,
      showLoginModal: false,
      showReportModal: false,
      socialShareModal: false,
      showImageModal: false,
      likeModalLoader: false,
      isDeleting: false,
      isFetchingComment: false,
      isBookmarkLoading: false,
      peopleList: [],
    };
  },

  computed: {
    ...mapGetters(["bookmarkedLists", "userDetails", "isUserAuthenticated"]),

    showReadMore() {
      return this.readMore && this.wordnLineCount > this.wordThreshold;
    },
  },

  mounted() {
    this.postViewerMode = !!this.$route.params.id;

    this.trimContent();

    this.likedAlready = this.article.likes.indexOf(this.userDetails.id) > -1;
    this.likesCount = this.article.likes.length;

    let index = this.bookmarkedLists.indexOf(this.article._id);
    if (~index) {
      this.bookmarked = true;
    }

    if (this.userDetails.id === this.article.author.id) {
      this.actionItem.push("Edit", "Delete");
    } else {
      this.actionItem.push("Report");
    }

    EventBus.$on("modalOkClicked", () => {
      // to be determined
    });
  },

  methods: {
    ...mapActions([
      "bookmarkPost",
      "removeBookmark",
      "saveComment",
      "likePost",
      "deletePost",
      "getLikedPeopleList",
      "sendNotification",
      "deleteMyComment",
      "editMyComment",
      "getAllComments",
      "signOut",
    ]),

    ...mapMutations(["setCurrentArticle"]),

    async getComments() {
      if (!this.userDetails.id) {
        this.showLoginModal = true;
        return;
      }

      if (this.showComments) {
        return;
      }

      try {
        this.isFetchingComment = true;
        let comments = await this.getAllComments({
          articleId: this.article._id,
          userId: this.userDetails.id,
        });

        this.article.comments = comments;

        this.showComments = true;
      } catch (e) {
        if (e.response.status === 401) {
          this.signOut();
        }
      } finally {
        this.isFetchingComment = false;
      }
    },

    trimContent() {
      this.wordsCount = 0;
      this.wordnLineCount = 0;
      this.trimedContent = "";
      let istrimmed = true;
      this.article.content = this.article.content.trim();

      for (let i = 0; i < this.article.content.length; i++) {
        if (
          this.article.content[i] === " " ||
          this.article.content[i] === "\n"
        ) {
          if (istrimmed && this.wordnLineCount >= this.wordThreshold) {
            this.trimedContent = this.article.content.substr(0, i);
            istrimmed = false;
          }
          if (this.article.content[i] === "\n") {
            this.wordnLineCount = this.wordnLineCount + 25;
          } else {
            this.wordnLineCount++;
            this.wordsCount++;
          }
        }
      }
    },

    goToPostViewer(articleId) {
      if (this.postViewerMode) {
        this.$router.back();
      } else {
        this.$router.push(`/post/${articleId}`);
      }
    },

    notifyUser(type, date) {
      if (this.article.author.id !== this.userDetails.id) {
        this.sendNotification({
          payload: {
            message: `${type} your post`,
            userId: this.userDetails.id,
            userName: this.userDetails.name,
            article: this.article.title,
            articleId: this.article._id,
            time: date,
            id: this.article.author.id,
          },
        });
      }
    },

    async liked() {
      if (!this.userDetails.id) {
        this.showLoginModal = true;
        return;
      }
      this.likedAlready ? this.likesCount-- : this.likesCount++;
      this.likedAlready = !this.likedAlready;

      try {
        let date = new Date();

        await this.likePost({
          id: this.article._id,
          userId: this.userDetails.id,
        });

        if (!this.likedAlready) this.notifyUser("liked", date);
      } catch (e) {
        console.error(e);
        this.likedAlready ? this.likesCount-- : this.likesCount++;
        this.likedAlready = !this.likedAlready;
        if (e.response.status === 401) {
          this.signOut();
          return;
        }

        this.$bvToast.toast(
          `Error occured while ${this.likedAlready ? "unliking" : "liking"}`,
          {
            title: `Error!`,
            autoHideDelay: this.dismissSecs,
            variant: `danger`,
            solid: true,
          }
        );
      }
    },

    async bookmark(postId) {
      if (!this.userDetails.id) {
        this.showLoginModal = true;
        return;
      }

      try {
        this.isBookmarkLoading = true;
        await this.bookmarkPost({ userId: this.userDetails.id, postId });

        this.$bvToast.toast(
          `Bookmarked ${!this.bookmarked ? "" : "removed"} successfully!`,
          {
            title: `${!this.bookmarked ? "Bookmarked" : "Bookmarked Removed"}`,
            autoHideDelay: this.dismissSecs,
            variant: `${!this.bookmarked ? "warning" : "danger"}`,
            solid: true,
          }
        );

        this.bookmarked = !this.bookmarked;
      } catch (e) {
        console.error(e);
        if (e.response.status === 401) {
          this.signOut();
          return;
        }
        this.$bvToast.toast(`An error occured, please try again`, {
          title: `Error!`,
          autoHideDelay: this.dismissSecs,
          variant: `danger`,
          solid: true,
        });
      } finally {
        this.isBookmarkLoading = false;
      }
    },

    async postComment() {
      if (!this.userDetails.id) {
        this.showLoginModal = true;
        return;
      }

      let date = new Date();
      if (this.myComment.length > 0) {
        try {
          if (!this.editCommentId) {
            await this.saveComment({
              id: this.article._id,
              comment: {
                userId: this.userDetails.id,
                comment: this.myComment,
                userName: this.userDetails.name,
                date,
              },
            });

            this.notifyUser("commented on", date);

            this.article.comments.unshift({
              userId: this.userDetails.id,
              comment: this.myComment,
              user: this.userDetails.name,
              image: this.userDetails.image,
              date,
            });
            this.myComment = "";
            this.showComments = true;
          } else {
            await this.editMyComment({
              articleId: this.article._id,
              userId: this.userDetails.id,
              commentId: this.editCommentId,
              comment: this.myComment,
            });

            this.article.comments.unshift({
              userId: this.userDetails.id,
              comment: this.myComment,
              user: this.userDetails.name,
              image: this.userDetails.image,
              date,
            });

            this.editCommentId = "";
            this.myComment = "";
          }
        } catch (e) {
          if (e.response.status === 401) {
            this.signOut();
          }
          EventBus.$emit("showCommonModal", {
            title: "Error",
            content: e.response.data.error,
            variant: "danger",
          });
        }
      }
    },

    async deleteArticle() {
      try {
        this.isDeleting = true;
        await this.deletePost({
          articleId: this.article._id,
          userId: this.userDetails.id,
        });
        EventBus.$emit("showCommonModal", {
          title: "Success",
          content: "Deleted Successfully",
          isEmit: true,
        });
      } catch (e) {
        if (e.response.status === 401) {
          this.signOut();
        }
        EventBus.$emit("showCommonModal", {
          title: "Error",
          content: e.response.data.error,
          variant: "danger",
        });
      } finally {
        this.isDeleting = false;
        this.showDeleteModal = false;
      }
    },

    async fetchPeopleLikedList() {
      if (!this.userDetails.id) {
        this.showLoginModal = true;
        return;
      }

      try {
        this.showPeopleModal = true;
        this.likeModalLoader = true;
        this.peopleList = await this.getLikedPeopleList({
          userList: this.article.likes,
        });
      } catch (e) {
        if (e.response.status === 401) {
          this.signOut();
        }
        EventBus.$emit("showCommonModal", {
          title: "Error",
          content: e.response.data.error,
          variant: "danger",
        });
      } finally {
        this.likeModalLoader = false;
      }
    },

    takeAction(action) {
      if (!this.userDetails.id) {
        this.showLoginModal = true;
        return;
      }
      if (action === "Edit") {
        this.setCurrentArticle(this.article);
        this.$router.push("/mypost");
      } else if (action === "Delete") {
        this.showDeleteModal = true;
      } else if (action === "Report") {
        this.showReportModal = true;
      }
    },

    async deleteComment(comment) {
      try {
        let newComment = await this.deleteMyComment({
          articleId: this.article._id,
          userId: this.userDetails.id,
          commentId: comment._id,
        });

        this.article.comments = newComment.comments;

        this.$bvToast.toast("Comment deleted successfully", {
          title: "Comment Deleted",
          autoHideDelay: 2000,
          variant: "warning",
          solid: true,
        });
      } catch (e) {
        if (e.response.status === 401) {
          this.signOut();
        }
        EventBus.$emit("showCommonModal", {
          title: "Error",
          content: e.response.data.error,
          variant: "danger",
        });
      }
    },

    editComment(comment, index) {
      this.editCommentId = comment._id;
      this.myComment = comment.comment;
      this.article.comments.splice(index, 1);
      this.$refs.commentBox.$el.focus();
    },
  },

  filters: {
    timeFilter(time) {
      return moment(time).format("DD MMM YY, hh:mm a");
    },
    headerFilter(header) {
      return header.charAt(0).toUpperCase() + header.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/post-viewer.scss";
</style>
